// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


// Fonts

/* latin */
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/user/themes/coinstrap4/fonts/news-cycle-400-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/user/themes/coinstrap4/fonts/news-cycle-700-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/user/themes/coinstrap4/fonts/eb-garamond-400-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/user/themes/coinstrap4/fonts/eb-garamond-400-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


//
// See bootstrap-npm-starter/node_modules/bootstrap/scss/_variables.scss
// for a list of default variable values
//


// Body overrides
$body-color: #222;
$body-bg: #f8f9fa;

// Style changes
$enable-rounded: false;

// Color overrides
$primary: #aa3399;  // Purple

// Font families
$font-family-sans-serif:    "News Cycle", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif:         "EB Garamond", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-monospace:     SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:          $font-family-sans-serif;

// Font sizes
$font-size-base:                1.35rem;
$enable-responsive-font-sizes:  true;
$rfs-base-font-size:            0.95rem;

// Headings and paragraphs
//$headings-font-weight:          700 !default; // Too bold
//$headings-line-height:          1.75; // Gives multiline headings too much gap
$headings-margin-bottom:        1.25rem;
$paragraph-margin-bottom:       1.5rem; // 1rem !default;

// Navigation tweaks
$navbar-nav-link-padding-x:     .75rem;
$dropdown-link-active-color:    $primary;
$dropdown-link-active-bg:       inherit;

// Breadcrumbs
//$breadcrumb-font-size:              75%; // null !default;
$breadcrumb-padding-y:              0rem;
//$breadcrumb-padding-x:              0.25rem;
$breadcrumb-item-padding:           .5rem;
//$breadcrumb-margin-bottom:          1rem !default;
//$breadcrumb-bg:                     $gray-200 !default;
//$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-divider-color:          #bbb;
//$breadcrumb-active-color:           $gray-600 !default;
//$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider:                quote(">");
//$breadcrumb-border-radius:          $border-radius !default;


// Caret style
$caret-width:                   .25em;
$caret-spacing:                 $caret-width * .75;

// Printing
$print-page-size: letter;


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

@import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

/*
// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

@import "icon-list";

body {
  padding: 3rem 1.5rem;
}

// Style Bootstrap icons
.bi {
  fill: currentColor;
}

*/

// Import custom styles
@import "coinstrap";