//// Body margin
body {
    margin-top: 55px;
}


//// Content
.main-content {
    font-family: $font-family-serif;
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-top: 2.5rem;
        font-family: $font-family-sans-serif;
    }
    li {
        @extend .my-3;
    }
    ol, ul, dl { margin-bottom: $paragraph-margin-bottom; }
    form, input, select, button, table, blockquote small, blockquote cite,
    .btn, nav, .nav, .navbar, .list-group, .breadcrumb, .breadcrumb-item, .badge,
    .card .pagination, .pager, .alert, .panel, .label, .jumbotron, .footer {
        font-family: $font-family-sans-serif;
        p { font-family: $font-family-sans-serif; }
        text-decoration: none;
        a { text-decoration: none; }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            margin-top: 0;
        }
    }
    .sidebar-content {
        font-family: $font-family-sans-serif;
        p { color: $gray-800; }
    }
    img { @extend .img-fluid; }
    code {
        background-color: inherit;
        color: inherit;
        padding: 0;
        margin: 0 0.1rem;
        font-family: inherit;
        font-variant: small-caps;
        letter-spacing: 0.1rem;
    }
    .jumbotron { text-align: center; }
    .badge { padding-bottom: 0.35em; }
    blockquote {
        //@extend .my-4;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        //@extend .px-5;
        //@extend .pt-4;
        //@extend .pb-2;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
        @extend .shadow-sm;
        background: white;
        border-left: 0.35rem solid $primary;
        color: $gray-700;
        cite {
            @extend .small;
            font-style: normal;
            display: block;
            text-align: right;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .footnotes {
        font-size: 75%;
    }
    span.logo-drop:after, .logo-drop > p:last-of-type:after {
        display: inline-block;
        margin-left: 0.75ex;
        vertical-align: middle;
        content: "";
        border-radius: 50%;
        height: 0.75ex;
        width: 1.5ex;
        background: #aaa;
    }
    nav + h1 { margin-top: 0; }
    form#contact div.buttons {
        text-align: center;
    }
}


//// Search
input.search-input {
    width: 100%;
}


//// Navbar
nav.navbar {
    a.navbar-brand {
        padding-top: 0;
        text-transform: lowercase;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        letter-spacing: -0.05rem;
        word-spacing: -0.1rem;
        white-space: nowrap;
        color: lighten($secondary, 10);
        &:hover { color: $secondary; }
        svg { margin-top: -0.3rem; margin-right: 0.4rem; }
    }
    input.search-input {
        @extend .rounded-pill;
        @extend .form-control-sm;
        @extend .px-3;
        width: 10rem !important;
        margin-left: 1rem;
    }
    li.active > a { @extend .text-primary; }
    .dropdown-menu {
        @extend .shadow;
        margin-top: 0;
        border: none;
        border-left: 0.35rem solid $primary;
    }
}
.fixed-top {
    transition: transform 0.2s;
    transform: translate(0, 0);
}
.sneakpeek--hidden {
    transform: translate(0, -100%);
}


//// Header
.backdrop {
    width: 100% !important;
    background-position: 50% 50%;
    background-size: cover;
}
.header-image {
    @extend .backdrop;
    height: 12.5vw;
    min-height: 75px;
    max-height: 150px;
}


//// Hero
.hero {
    @extend .p-0;
    @extend .mb-5;
    background-size: cover;
    background-position-y: center;
    .container {
        @extend .py-5;
    }
    &.dark {        
        .hero-overlay { background: rgba(0,0,0, 0.5); }
    }
    &.light {        
        .hero-overlay { background: rgba(255,255,255, 0.5); }
    }
}


//// Button tweaks for News Cycle font
.btn {
    padding-bottom: 0.5rem;
}


//// Breadcrumbs
.breadcrumb {
    padding: 0;
    background: inherit;
}


//// Sidebars
.article-sidebar {
    .list-group {
        a.list-group-item {
            padding-left: 2.5rem;
            text-indent: -1.25rem;
        }
    }
}


//// For icons inside inputs
.inner-addon {
    position: relative;
    .fa {
        color: $gray-400;
        position: absolute;
        padding: 0.75rem;
        pointer-events: none;
    }
}
.left-addon {
    .fa { left: 0; }
    input { padding-left: 1rem; }
}
.right-addon {
    .fa { right: 0; }
    input { padding-right: 1rem; }
}


//// HTTP errors (e.g. 404)
#error {
    padding: 5rem 0;
    h1.display-1 { color: $gray-500; }
}


//// No-click dropdown menus
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}


//// Cards
.card-columns {
    @include media-breakpoint-only(xl) {
        column-count: 3;
    }
    @include media-breakpoint-only(lg) {
        column-count: 3;
    }
    @include media-breakpoint-only(md) {
        column-count: 3;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
}
.card {
    background-size: cover;
    background-position: center 25%;
    .card-footer { border-top: none; }
    .card-text {   
        blockquote {
            padding: 1rem 1.5rem;
            margin: 0;
        }
    }
}
.article-cards {
    .card {
        &:hover { @extend .shadow; }
        h4 { margin-top: 0; }
        p {
            @extend .small;
            margin-bottom: 0;
        }
    }
}
.blog-cards {
    .card-columns {
        .col { @extend .px-2; }
    }
    .card {
        &:hover { @extend .shadow; }
        border: none;
        text-shadow: 0 0 2.5ex rgba(0,0,0, 1.0);
        h4 { margin-top: 0.75rem; margin-bottom: 1.0rem; }
        p {
            @extend .small;
            margin-bottom: 0;
        }
        img { @extend .shadow-sm; }
        a.btn-dark:hover { @extend .btn-primary; }
    }
    .card-header, .card-footer {
        border: none;
        background: rgba(24,24,24, 0.1);
    }
    .card-tint {
        border: none;
        background-color: rgba(32,32,32, 0.65);
    }
}
.resource-cards {
    .card {
        &:hover { @extend .shadow; }
        a.btn-secondary:hover { @extend .btn-primary; }
    }
}


//// Media
.media {
    .bio {
        p {
            line-height: 1.4;
            margin-bottom: 0;
        }
    }
}


//// Footer
.footer {
    @extend .py-5;
    @extend .mt-5;
    color: white;
    a {
        color: $gray-500;
        &:hover { color: white; }
    }
    background: $gray-700;
    box-shadow: 0 50vh 0 50vh $gray-700;   
}


//// Slideout navigation menu
$slide-width: 15rem;
#slidenav {
    &.expanded { width: $slide-width; }
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: $gray-800; //$primary;
    overflow-x: hidden;
    transition: 0.25s;
    box-shadow: 0px 0px 20px rgba(0,0,0, 0.25);
    .slidenav-content {
        width: $slide-width - 3rem;
        @extend .my-4;
        @extend .mx-4;
    }
    ul.nav {
        list-style: none;
        margin-top: 2rem;
        li {
            padding: 0;
            &.active > a { color: white; }
            a.active { color: white; }
        }
        ul {
            list-style: none;
            border-left: 0.25rem solid darken($primary, 5%);
            margin-left: 1rem;
            padding-left: 1rem;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        a {
            text-decoration: none;
            color: $gray-400;
            padding: 0.25rem 0;
        }
        a:hover, .offcanvas a:focus {
            color: white;
        }
    }
    form {
        margin-top: 2.5rem;
        &.inner-addon i.fa { color: rgba(255,255,255,0.4); }
        .search-input {
            margin-left: 0;
            width: 100%;
            border: none;
            background: $gray-700;
            color: white;
            @extend .rounded-pill;
            @extend .form-control-sm;
            @extend .px-3;
        }
    }
}


//// Animated menu toggle button
.navtoggle {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: 0.25s;
    background-color: transparent;
    &:focus {
        outline: none;
    }
    & span {
        display: block;
        position: absolute;
        border-radius: 10px;
        top: 16px;
        left: 3px;
        right: 3px;
        height: 4px;
        background: $primary;
        transition: 0.25s;
    }
    & span::before,
    & span::after {
        display: block;
        position: absolute;
        border-radius: 10px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $primary;
        content: "";
    }
    & span::before {
        top: -9px;
    }
    & span::after {
        bottom: -9px;
    }
    &.is-active {
        background-color: transparent;
        transform: rotate(90deg);
        span {
            background: none;
        }
        span::before {
            top: 0;
            transform: translateX(0px) rotate(45deg);
            transform-origin: 50% 0%;
        }
        span::after {
            bottom: 0;
            transform: translateY(-1px) rotate(-45deg);
            transform-origin: 50% 100%;
        }
        span::before,
        span::after {
            transition: 0.25s;
        }
    }
}


//// Previous/Next image links
section#navlinks {
    margin-top: 2.5rem;
    a.linkbox {
        &:hover {
            text-decoration: none;
            div.content { background: rgba(0,0,0, 0.3); }
        }
        display: block;
        color: white;
        text-shadow: 1px 1px 5px rgba(0,0,0, 0.75);
        text-decoration: none;
        background-color: $gray-500;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 100%;
        div.content {
            padding: 15px 20px;
            background: rgba(0,0,0, 0.5);
            height: 100%;
            p {
                margin-bottom: 0.5rem;
                font-family: $font-family-sans-serif;
                font-size: 1rem;
            }
            h2 {
                font-family: $font-family-sans-serif;
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: none;
                margin-top: 0;
            }
            &.prev {
                text-align: left;
                span { margin-right: 0.5rem; }
            }
            &.next {
                text-align: right;
                span { margin-left: 0.5rem; }
            }
        }
    }
}
